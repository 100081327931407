<template>
  <div class="backtop">
    <a href="https://www.live800.com/" class="live">
      <i class="iconfont icon-kefu"></i>
    </a>
    <div class="toTop" @click="ToTop" v-if="btnFlag">
      <img src="../../../assets/img/top.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BackTop",
  data() {
    return {
      btnFlag:false,
      scrollTop:0
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    //点击返回顶部
    ToTop() {
      let timer = setInterval(() => {
        let ispeed = Math.floor(-this.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          this.scrollTop + ispeed;
        if (this.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 20);
    },
    scrollToTop() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop = scrollTop;
      if (this.scrollTop > 100) {
        this.btnFlag = true;
      } else {
        this.btnFlag = false;
      }
    },
  },
  components: {},
};
</script>

<style scoped lang="less">
.backtop{
  position: fixed;
  bottom: 20%;
  right: 2%;
  z-index: 9;
}
.live{
  display: none;
  // display: block;
  cursor: pointer;
  text-align: center;
  margin-bottom: 5px;
  .iconfont{
    color: #3051B1;
    font-size:32px;
  }
}
.toTop {
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  img {
    width: 100%;
  }
}
</style>