<template>
  <div class="jobs">
    <div class="title">{{$t('jobs.title1')}}</div>
    <div class="content">

        <div class="job-item" v-for="item in jobs" :key="item.id">
            <div class="job-name">
                <span class="job-name-text">{{item.name}}</span>
            </div>
            <!-- 任职要求 -->
            <div class="job-requirements">
                <div class="item-title">{{$t('jobs.title2')}}</div>
                <p v-html="item.jobRequirements.replace(/(\r\n)|(\n)/g,'<br>')"></p>
            </div>
            <!-- 职位描述 -->
            <div class="job-desc">
                <div class="item-title">{{$t('jobs.title3')}}</div>
                <div class="desc-text">
                    <p v-html="item.description.replace(/(\r\n)|(\n)/g,'<br>')"></p>
                </div>
            </div>
            <div class="btn">
                <button><a style="color:#fff" :href="'mailto:'+item.email">{{$t('jobs.title4')}}</a></button>
            </div>
        </div>
    </div>
    <back-top></back-top>
  </div>
</template>

<script>
import BackTop from '../../components/content/backTop/BackTop.vue';
import {getJobs} from '../../network/jobs'
export default {
  name: "Jobs",
  data() {
    return {
        jobs:[]
    };
  },
  created(){
      this.getJobs()
  },
  methods: {
      //获取工作机会
      getJobs(){
          getJobs().then((res)=>{
            //   console.log(res.data);
              this.jobs=res.data
          })
      }
  },

  components: {
      BackTop
  },

  computed: {},
};
</script>
<style lang='less' scoped>
@import "../../assets/css/Jobs.less";
</style>